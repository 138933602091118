import React from "react";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import {
  DiReact,
  DiMongodb,
  DiNodejs,
  DiBootstrap,
  DiSass,
  DiJavascript1,
  DiPython,
  DiJava,
} from "react-icons/di";
import { CgCPlusPlus } from "react-icons/cg";
import { SiTypescript } from "react-icons/si";
import Tooltip from "@mui/material/Tooltip";
const About = () => {
  return (
    <div className="about-container">
      <div className="nav">
        <Navbar />
      </div>
      <div className="main-content">
        <div className="about-header">
          <h5>
            Get to <span>Know</span> Me
          </h5>
          <div className="bar"></div>
        </div>
        <div className="text-container">
          <p>
            I'm Ian, a senior studying Computer Science at Southern Illinois
            University and a self taught web developer. I enjoy using the latest
            tech such as React and MongoDB to create web apps in my spare time.
            Some of my work can be found on the projects page or on my{" "}
            <a href="https://github.com/ibucksss">Github</a>.
          </p>
        </div>
      </div>
      <div className="tech-container">
        <div className="tech-title">
          <h5>Technology I Work With</h5>
        </div>
        <div className="tech-icons">
          <Tooltip title="React" placement="bottom">
            <div>
              <DiReact className="react-icon tech-icon " />
            </div>
          </Tooltip>
          <Tooltip title="MongoDB" placement="bottom">
            <div>
              <DiMongodb className="mongo-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="NodeJS">
            <div>
              <DiNodejs className="node-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="Bootstrap">
            <div>
              <DiBootstrap className="bootstrap-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="Sass">
            <div>
              <DiSass className="sass-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="TypeScript">
            <div>
              <SiTypescript className="typescript-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="JavaScript">
            <div>
              <DiJavascript1 className="java-script-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="Python">
            <div>
              <DiPython className="python-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="Java">
            <div>
              <DiJava className="java-icon tech-icon" />
            </div>
          </Tooltip>
          <Tooltip title="C++">
            <div>
              <CgCPlusPlus className="cpp-icon tech-icon" />
            </div>
          </Tooltip>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default About;
