export const projects = [
  {
    _id: "1",
    title: "ETRAINR Fitness",
    description:
      "App that tracks users workouts that can be viewed in table and graphical form. Allows users to search for new exercises based on goals and equipment by utilizing an API.  Uses the MERN stack to create a responsive user interface and backend tools to for seemless account creation and tracking.  ",

    live: "https://etrainr.herokuapp.com",
    repo: "https://github.com/ibucksss/etrainr-fitness",
    tech: "React, MongoDB, NodeJS, Express, AntD",
  },
  {
    _id: "2",
    title: "Resume Craft",
    description:
      "Web-based tool that assists users in creating a professional resume quickly by auto filling the users selected template with information for their profiles.   ",

    live: "https://resumecraftapp.herokuapp.com",
    repo: "https://github.com/ibucksss/resumecraft",
    tech: "React, MongoDB, NodeJS, Express, AntD",
  },
  {
    _id: "3",
    title: "Pocket Pal",
    description:
      "A finance tracker built using React, MongoDB and AntD. Includes user authorization, various ways to view your transactions and add/edit ability. ",

    live: "https://pocketpalfinal.herokuapp.com/login",
    repo: "https://github.com/ibucksss/pocketpalfinal",
    tech: "React, MongoDB, NodeJS, Express, AntD",
  },
  {
    _id: "5",
    title: "Doist-ToDo",
    description:
      "Simple React app created using basic hooks. Includes the ability to add, complete and delete tasks as well as editing in line.",
    live: "https://doist-todo.herokuapp.com/",
    repo: "https://github.com/ibucksss/dosit-todo",
    tech: "React, Bootstrap",
  },
  {
    _id: "6",
    title: "Ian's Tours",
    description:
      "Simple website of a tour company displaying the companies services, gallery, about section and photos of locations.",
    live: "https://ianstours.netlify.app/",
    repo: "https://github.com/ibucksss/Backroad-Tour-Website-flexbox",
    tech: "HTML, CSS, JavaScript",
  },
  {
    _id: "7",
    title: "Find The Fraud",
    description:
      "Game created to work on my JavaScript skills. Game includes player creation, validation of the correct amount of players and more.",
    live: "https://findthefraud.netlify.app/",
    repo: "https://github.com/ibucksss/Find-The-Fraud",
    tech: "HTML, CSS, JavaScript",
  },
];
