import React from "react";
import { Navbar } from "../components/Navbar";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Footer />
    </>
  );
};
export default Home;
