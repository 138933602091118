import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
export const Footer = () => {
  return (
    <div className="footer mx-auto">
      <div className="footer-icons">
        <a href="https://github.com/ibucksss">
          <FaGithub />
        </a>
        <a href="https://www.linkedin.com/in/iankbuckles/">
          <FaLinkedin />
        </a>
        <a href="mailto:ian.buckles@siu.edu">
          <HiOutlineMail />
        </a>
      </div>
      <div className="footer-text">
        Made by <span>Ian</span>
      </div>
    </div>
  );
};
