import React from "react";
import portrait from "../assets/portrait.png";
import { BiMessage } from "react-icons/bi";
import {
  DiReact,
  DiMongodb,
  DiNodejs,
  DiBootstrap,
  DiSass,
} from "react-icons/di";
import { AiFillGithub } from "react-icons/ai";
import { SiTypescript } from "react-icons/si";
import { FiFolder } from "react-icons/fi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
export const Header = () => {
  return (
    <div className="container">
      <div className="row row-1    ">
        <div className="col-lg-5 col-12  ">
          <div className="about">
            <div className="left">
              <img src={portrait} alt="" />
              <div className="info">
                <p className="intro">
                  Hey, I'm <span className="firstname">Ian</span>
                </p>
                <p>A student Software Developer</p>
              </div>
            </div>
            <div className="right">
              <a href="/about">
                <MdOutlineArrowForwardIos className="icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="contact-container col-lg-3 col-12">
          <div className="contact-icon">
            <a href="mailto: ian.buckles@siu.edu">
              <BiMessage className="icon" />
            </a>
          </div>
          <div className="contact-text">
            <a href="mailto: ian.buckles@siu.edu">Contact</a>
          </div>
        </div>

        <div className="github-container col-lg-3 col-12">
          <div className="github-icon">
            <a href="https://github.com/ibucksss">
              <AiFillGithub className="icon" />
            </a>
          </div>
          <div className="github-text">
            <a href="https://github.com/ibucksss">Github</a>
          </div>
        </div>
      </div>
      <div className="row row-2">
        <div className="col-lg-8 col-12">
          <div className="tech">
            <div className="tech-header">
              <p>Tech I Work With</p>
            </div>
            <div className="tech-icons">
              <Tooltip title="React" placement="bottom">
                <div>
                  <DiReact className="react-icon tech-icon " />
                </div>
              </Tooltip>
              <Tooltip title="MongoDB" placement="bottom">
                <div>
                  <DiMongodb className="mongo-icon tech-icon" />
                </div>
              </Tooltip>
              <Tooltip title="NodeJS">
                <div>
                  <DiNodejs className="node-icon tech-icon" />
                </div>
              </Tooltip>
              <Tooltip title="Bootstrap">
                <div>
                  <DiBootstrap className="bootstrap-icon tech-icon" />
                </div>
              </Tooltip>
              <Tooltip title="Sass">
                <div>
                  <DiSass className="sass-icon tech-icon" />
                </div>
              </Tooltip>
              <Tooltip title="TypeScript">
                <div>
                  <SiTypescript className="typescript-icon tech-icon" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 project-container">
          <div className="project-icon">
            <a href="/projects">
              <FiFolder className="icon" />
            </a>
          </div>
          <div className="project-text">
            <a href="/projects">Projects</a>
          </div>
        </div>
      </div>
    </div>
  );
};
