import { React, useState } from "react";
import { Navbar } from "../components/Navbar";
import { projects } from "../resources/projects";
import { Footer } from "../components/Footer";
const Projects = () => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  let width = window.innerWidth;
  if (width > 805) {
    return (
      <div className="projects-container">
        <Navbar />
        <div className="project-content">
          <div className="title">Projects</div>
          <div className="projects">
            <div className="projects-name">
              {projects.map((project, index) => (
                <div key={index}>
                  <h1
                    onClick={() => {
                      console.log("test");
                      setSelectedItemIndex(index);
                    }}
                    className={`${
                      selectedItemIndex === index ? "active" : "inactive"
                    }`}
                  >
                    {project.title}
                  </h1>
                </div>
              ))}
            </div>
            <div className="projects-info">
              <h1>{projects[selectedItemIndex].description}</h1>
              <h1 className="tech-used">
                Tech Used: {projects[selectedItemIndex].tech}
              </h1>
              <div className="projects-links">
                <a href={projects[selectedItemIndex].live}>Live</a>
                <a href={projects[selectedItemIndex].repo}>Repo</a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="projects-container sm-container">
        <Navbar />
        <div className="project-content sm-content">
          <div className="title">Projects</div>
          <div className="projects sm-projects">
            <div className="projects-name sm-name">
              {projects.map((project, index) => (
                <div key={index}>
                  <h1
                    onClick={() => {
                      setSelectedItemIndex(index);
                    }}
                    className={`${
                      selectedItemIndex === index
                        ? "active projects-title"
                        : "inactive projects-title"
                    }`}
                  >
                    {project.title}
                  </h1>
                </div>
              ))}
            </div>
            <div className="projects-info">
              <h1>{projects[selectedItemIndex].description}</h1>
              <h1 className="tech-used">
                Tech Used: {projects[selectedItemIndex].tech}
              </h1>
              <div className="projects-links">
                <a href={projects[selectedItemIndex].live}>Live</a>
                <a href={projects[selectedItemIndex].repo}>Repo</a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  // return (
  //   <div className="projects-container">
  //     <Navbar />
  //     <div className="project-content">
  //       <div className="title">Projects</div>
  //       <div className="projects">
  //         <div className="projects-name">
  //           {projects.map((project, index) => (
  //             <div key={index}>
  //               <h1
  //                 onClick={() => {
  //                   console.log("test");
  //                   setSelectedItemIndex(index);
  //                 }}
  //                 className={`${
  //                   selectedItemIndex === index ? "active" : "inactive"
  //                 }`}
  //               >
  //                 {project.title}
  //               </h1>
  //             </div>
  //           ))}
  //         </div>
  //         <div className="projects-info">
  //           <h1>{projects[selectedItemIndex].description}</h1>
  //           <h1 className="tech-used">
  //             Tech Used: {projects[selectedItemIndex].tech}
  //           </h1>
  //           <div className="projects-links">
  //             <a href={projects[selectedItemIndex].live}>Live</a>
  //             <a href={projects[selectedItemIndex].repo}>Repo</a>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <Footer />
  //   </div>
  // );
};
export default Projects;
