import React, { useState } from "react";
import logo from "../assets/logo.png";
import { FaBars } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import "../styles/styles.css";
export const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <div className={toggle ? "nav-container expanded" : "nav-container"}>
      <div className="logo">
        <a href="/">
          <img src={logo} alt="" />
        </a>
      </div>

      <div className="toggle" onClick={handleToggle}>
        {toggle ? (
          <RiCloseLine className="icon" />
        ) : (
          <FaBars className="icon" />
        )}
      </div>
      <div className="links">
        <a href="/about">About</a>
        <a href="/projects">Projects</a>
      </div>
    </div>
  );
};
